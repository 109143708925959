// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tapkey-addons-app-primary: mat.define-palette(mat.$blue-palette);
$tapkey-addons-app-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tapkey-addons-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tapkey-typography: mat.define-typography-config(
  $font-family: 'Nunito Sans'
);
$tapkey-addons-app-theme: mat.define-light-theme((color: (primary: $tapkey-addons-app-primary,
        accent: $tapkey-addons-app-accent,
        warn: $tapkey-addons-app-warn,
      ),
      typography: $tapkey-typography), );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.tk-addons-app-container {
  padding: 0 20px;
}
.tk-addons-app-container,
.addons-snackbar {
  @include mat.all-component-themes($tapkey-addons-app-theme);
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
}

/* Workaround required for MudBlazor Z Index*/
.cdk-overlay-container {
  @include mat.all-component-themes($tapkey-addons-app-theme);
  font-family: 'Nunito Sans', sans-serif;
  z-index: 1100;
}

.form-field-full-width {
  width: 100%;
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}